import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button, Colors, Icon } from '@class101/ui';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { Component as Toast, Position, showToast } from './Toast';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toast"
    }}>{`Toast`}</h1>
    <p>{`오류, 알림 등을 알리기에 유용한 컴포넌트입니다. `}<inlineCode parentName="p">{`Toaster.create`}</inlineCode>{`를 사용합니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Toast} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`let AppToaster;
export let count = 0;

export async function showToast(props: ToastProps) {
  if (!AppToaster) {
    AppToaster = await Toaster.create();
  }
  AppToaster.show(props);
}
`}</code></pre>
    <Playground __position={1} __code={'<Button onClick={() => showToast({ message: \'hi\' })}>Show Toast!</Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Colors,
      Icon,
      Playground,
      Props,
      useState,
      Toast,
      Position,
      showToast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button onClick={() => showToast({
        message: 'hi'
      })} mdxType="Button">Show Toast!</Button>
    </Playground>
    <h2 {...{
      "id": "positions"
    }}>{`Positions`}</h2>
    <Playground __position={2} __code={'<Button\n  onClick={() => showToast({ message: \'top hi\', position: Position.TOP })}\n>\n  Show Top Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({ message: \'top left hi\', position: Position.TOP_LEFT })\n  }\n>\n  Show Top Left Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({ message: \'top right hi\', position: Position.TOP_RIGHT })\n  }\n>\n  Show Top Right Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({ message: \'bottom hi\', position: Position.BOTTOM })\n  }\n>\n  Show Bottom Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({ message: \'bottom left hi\', position: Position.BOTTOM_LEFT })\n  }\n>\n  Show Bottom Left Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({ message: \'bottom right hi\', position: Position.BOTTOM_RIGHT })\n  }\n>\n  Show Bottom Right Toast!\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Colors,
      Icon,
      Playground,
      Props,
      useState,
      Toast,
      Position,
      showToast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button onClick={() => showToast({
        message: 'top hi',
        position: Position.TOP
      })} mdxType="Button">Show Top Toast!</Button>
  <Button onClick={() => showToast({
        message: 'top left hi',
        position: Position.TOP_LEFT
      })} mdxType="Button">
    Show Top Left Toast!
  </Button>
  <Button onClick={() => showToast({
        message: 'top right hi',
        position: Position.TOP_RIGHT
      })} mdxType="Button">
    Show Top Right Toast!
  </Button>
  <Button onClick={() => showToast({
        message: 'bottom hi',
        position: Position.BOTTOM
      })} mdxType="Button">Show Bottom Toast!</Button>
  <Button onClick={() => showToast({
        message: 'bottom left hi',
        position: Position.BOTTOM_LEFT
      })} mdxType="Button">
    Show Bottom Left Toast!
  </Button>
  <Button onClick={() => showToast({
        message: 'bottom right hi',
        position: Position.BOTTOM_RIGHT
      })} mdxType="Button">
    Show Bottom Right Toast!
  </Button>
    </Playground>
    <h2 {...{
      "id": "icons--backgrounds"
    }}>{`Icons & Backgrounds`}</h2>
    <Playground __position={3} __code={'<Button\n  onClick={() =>\n    showToast({\n      backgroundColor: Colors.redError,\n      message: \'오류입니다.\',\n      icon: <Icon.Alert fillColor={Colors.white} />,\n    })\n  }\n>\n  Show Warning Toast!\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Colors,
      Icon,
      Playground,
      Props,
      useState,
      Toast,
      Position,
      showToast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button onClick={() => showToast({
        backgroundColor: Colors.redError,
        message: '오류입니다.',
        icon: <Icon.Alert fillColor={Colors.white} />
      })} mdxType="Button">
    Show Warning Toast!
  </Button>
    </Playground>
    <h2 {...{
      "id": "with-action"
    }}>{`with Action`}</h2>
    <Playground __position={4} __code={'<Button\n  onClick={() =>\n    showToast({\n      button: \'실행취소\',\n      message: \'코멘트를 보냈습니다.\',\n      icon: <Icon.CheckCircle fillColor={Colors.white} />,\n      timeout: 0,\n    })\n  }\n>\n  Show Toast!\n</Button>\n<Button\n  onClick={() =>\n    showToast({\n      backgroundColor: Colors.redError,\n      button: <Icon.Close fillColor={Colors.white} />,\n      message: \'오류입니다.\',\n      icon: <Icon.Alert fillColor={Colors.white} />,\n      timeout: 0,\n    })\n  }\n>\n  Show Toast!\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Button,
      Colors,
      Icon,
      Playground,
      Props,
      useState,
      Toast,
      Position,
      showToast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button onClick={() => showToast({
        button: '실행취소',
        message: '코멘트를 보냈습니다.',
        icon: <Icon.CheckCircle fillColor={Colors.white} />,
        timeout: 0
      })} mdxType="Button">
    Show Toast!
  </Button>
  <Button onClick={() => showToast({
        backgroundColor: Colors.redError,
        button: <Icon.Close fillColor={Colors.white} />,
        message: '오류입니다.',
        icon: <Icon.Alert fillColor={Colors.white} />,
        timeout: 0
      })} mdxType="Button">
    Show Toast!
  </Button>
    </Playground>
    <h2 {...{
      "id": "update-toast"
    }}>{`Update Toast`}</h2>
    <Playground __position={5} __code={'(() => {\n  const [key, setKey] = useState(null)\n  return (\n    <Button\n      onClick={() =>\n        setKey(\n          showToast({\n            key,\n            message: new Date(),\n            timeout: 3000,\n          }),\n        )\n      }\n    >\n      Show Toast!\n    </Button>\n  )\n})()'} __scope={{
      props,
      DefaultLayout,
      Button,
      Colors,
      Icon,
      Playground,
      Props,
      useState,
      Toast,
      Position,
      showToast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {(() => {
        const [key, setKey] = useState(null);
        return <Button onClick={() => setKey(showToast({
          key,
          message: new Date(),
          timeout: 3000
        }))} mdxType="Button">
        Show Toast!
      </Button>;
      })()}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      